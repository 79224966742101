export const LANDING_PAGE = "/";
export const HOME_ROUTE = "/homes";
export const ERROR_404 = "/homes/404";
export const HOME_DETAILS = "/homes/details/";
export const HOME_DETAILS_SHARED = "/homes/details/shared/";
export const FAVORITES_ROUTE = "/homes/favorites/";
export const PROFILE_ROUTE = "/homes/profile/";
export const SETTINGS_ROUTE = "/homes/settings/";
export const CREATE_ACCOUNT_ROUTE = "/create-account";
export const LOGIN_ROUTE = "/login-account";
export const FORGOT_PASSWORD = "/login-forgot-password";