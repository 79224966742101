import * as TEXTS from "../i18nText"

export default {
    [TEXTS.DISCLAIMER_TITLE_TEXT]: "Datos MLS proporcionados bajo licencia de la",
    [TEXTS.DISCLAIMER_TITLE_LINK_TEXT]: "Red Nacional de Corretaje",
    [TEXTS.PAYMENT_BREAKDOWN_TITLE_TEXT]: "Este no es un anuncio de un préstamo hipotecario ni una oferta para otorgar crédito.",
    [TEXTS.PAYMENT_BREAKDOWN_FIRST_TEXT]: "El pago estimado se basa en supuestos de seguro, impuestos y una tasa de interés de muestra del 7,250 % que utilizamos en el momento en que lo calificamos. Estos factores pueden ser diferentes al momento del cierre.",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_TEXT]: "El pago estimado se basa en supuestos de seguros, impuestos y el ",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT]: "programa de préstamos para el que califica. Estos factores pueden ser diferentes al momento del cierre.",
    [TEXTS.IMPORT_PROP_ONE_TEXT]: "Usted puede importar una propiedad desde",
    [TEXTS.IMPORT_PROP_TWO_TEXT]: "proveyendo la URL de la propiedad",
    [TEXTS.IMPORT_PROP_O_TEXT]: "o",
    [TEXTS.THERE_IS_NO_LOAN_O_TEXT]: "Haga clic aquí para invitar a su prestamista a cargar la aprobación de su préstamo y desbloquear todas las funciones de Padzilly, incluido el reembolso/contribución del vendedor",
    [TEXTS.PLEASE_AUTHENTICATE_TEXT]: "Regístrese para guardar sus propiedades y búsquedas favoritas e invite a su prestamista a cargar la aprobación de su préstamo",
    [TEXTS.CURRENTLY_AS_GUEST_TEXT]: "Actualmente estás navegando como invitado",
    [TEXTS.AMOUNTS_BELOW_TEXT]: "Los montos a continuación provienen de la aprobación de su préstamo",
    [TEXTS.REMOVE_LOAN_ORIGINATOR_TEXT]: "¿Estás seguro de que quieres eliminar el originador de préstamos asociado?",
    [TEXTS.DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT]: "Por favor, complete los siguientes campos",
    [TEXTS.LOAN_OFFICER_CONNECTED_TEXT]: "Su oficial de préstamos está conectado con Padzilly",
    [TEXTS.WE_ARE_CONTACTING_TEXT]: "Nos estamos comunicando con ellos para cargar su perfil de aprobación de préstamo de inmediato",
    [TEXTS.GETTING_PROPS_TEXT]: "Obteniendo propiedades, por favor espere",
    [TEXTS.GETTING_DETAILS_TEXT]: "Obteniendo la propiedad, por favor espere",
    [TEXTS.FORGOT_PASS_OTP_TEXT]: "Le enviaremos un correo electrónico con una contraseña de un solo uso (OTP) para restablecer su contraseña.",
    [TEXTS.VERIFICATION_TEXT]: "Por favor ingrese el código de verificación que le enviamos a su dirección de correo electrónico",
    [TEXTS.CHECK_SPAM_TEXT]: "Revisa tu carpeta de spam y basura si no está en tu bandeja de entrada. Esto puede tardar entre 2 y 3 minutos",
    [TEXTS.LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT]: "¡Creemos su perfil de búsqueda de casas en Padzilly SearchPro!",
    [TEXTS.CHOOSE_PRE_APPROVED_TEXT]: "Elija esto si tiene una calificación o aprobación actual de un prestamista",
    [TEXTS.CHOOSE_NOT_APPROVED_TEXT]: "Elija esto si no tiene una aprobación, pero desea iniciar la búsqueda inteligente ahora",
    [TEXTS.CREATE_SWIFTMATCH_PROFILE_TEXT]: "¡Ningún problema! Creemos tu perfil de SearchPro",
    [TEXTS.FOR_BEST_RESULTS_TEXT]: "Para obtener los mejores resultados, se recomienda una precalificación de prestamista relacionada con Padzilly",
    [TEXTS.IN_THE_MEANTIME_TEXT]: "Mientras tanto, ¡probemos SearchPro!",
    [TEXTS.WE_WILL_REPLACE_TEXT]: "Reemplazaremos esto con los datos de su préstamo una vez que se cargue",
    [TEXTS.WE_WILL_CHECK_LOAN_OFFICER_TEXT]: "Verificaremos si su oficial de préstamos ya está conectado con Padzilly",
    [TEXTS.GREAT_NEWS_TEXT]: "¡Gran noticia! Su oficial de préstamos está conectado con Padzilly",
    [TEXTS.LOAN_OFFICER_NOT_CONNECTED_TEXT]: "Parece que su oficial de préstamos aún no está conectado con Padzilly",
    [TEXTS.WE_ARE_REACHING_OUT_TEXT]: "Nos estamos comunicando con ellos para que puedan registrarse y hacer que su viaje de compra de casas sea más fácil y rápido",
    [TEXTS.GREAT_NEWS_TWO_TEXT]: "¡Gran noticia! Su prestamista está conectado con Padzilly y encontramos la aprobación de su préstamo",
    [TEXTS.CONTINUE_SEARCH_TEXT]: "Puedes ir a Casas y continuar tu búsqueda",
    [TEXTS.ENTER_INFO_FOR_SEARCH_TEXT]: "Por favor, ingrese una ciudad, código postal o dirección para buscar",
    [TEXTS.NO_MATCHING_PROPERTIES_TEXT]: "Parece que no pudimos encontrar ninguna propiedad para tus criterios de búsqueda, ¡posiblemente porque todavía estamos ampliando la cobertura!",
    [TEXTS.CHANGE_PROPERTY_TYPE_TEXT]: "Cambie el tipo de propiedad, las habitaciones y los filtros anteriores",
    [TEXTS.FIND_IN_ZILLOW_OR_REALTOR_TEXT]: "Si no puede encontrar una propiedad específica, puede buscar en Zillow o Realtor.com y compartirla con",
    [TEXTS.CHECK_MLS_TEXT]: "Vista alternativa: Ver lista de condados activos. ",
    [TEXTS.PROBLEM_LOADING_PROPERTIES_TEXT]: "Hubo un problema al cargar las propiedades",
    [TEXTS.CHECK_YOUR_CONNECTION_TEXT]: "Por favor, compruebe la conexión y vuelva a intentarlo",
    [TEXTS.WE_COULDNT_FIND_PROP_TEXT]: "Lo sentimos, no pudimos encontrar la propiedad",
    [TEXTS.FAVORITES_GUEST_TEXT]: "Se requiere una cuenta para acceder a su listado de propiedades favorito. Regístrese para crear una cuenta o inicie sesión si tiene una",
    [TEXTS.OFFER_PRO_BACKDROP_TEXT]: "Utilice OfferPro para calificar o asegurar la mejor oferta ajustando el precio y otros factores",
    [TEXTS.VERIFY_MONTHLY_ASSOCIATION_TEXT]: "Verifique la tarifa mensual de la asociación y el monto del impuesto anual a la propiedad para garantizar una aprobación precisa",
    [TEXTS.NOT_SAVED_PROPERTIES_TEXT]: "No has guardado ninguna propiedad como favorita",
    [TEXTS.EXPLORE_AND_SAVE_PROPERTIES_TEXT]: "Explora y guarda tus propiedades preferidas",
    [TEXTS.YOU_APPEAR_QUALIFIED_TEXT]: "¡Pareces calificado para esta casa!",
    [TEXTS.CASH_NEEDED_TEXT]: "Puede calificar si se reduce el efectivo necesario. Intente ajustar a continuación",
    [TEXTS.PAYMENT_NEEDED_TEXT]: "Puede calificar si se reduce el pago. Intente ajustar a continuación",
    [TEXTS.PAYMENT_AND_CASH_NEEDED_TEXT]: "Puede calificar si se reducen el pago y el efectivo necesarios. Intente ajustar a continuación",
    [TEXTS.MUST_BE_GREATER_THAN_TEXT]: "debe ser mayor que 0",
    [TEXTS.MORTGAGE_PAYMENT_ERROR_TEXT]: "debe ser menor que los Fondos disponibles",
    [TEXTS.VALID_URL_TO_IMPORT_ERROR_TEXT]: "debe ser una URL válida de Realtor o Zillow",
    [TEXTS.INVALID_EMAIL_ERROR_TEXT]: "Correo electronico inválido, ej: joe@padzilly.com",
    [TEXTS.ONLY_LETTERS_ERROR_TEXT]: "debe tener solo letras",
    [TEXTS.NO_MLO_FOUND_ERROR_TEXT]: "No se encontró ningún oficial de préstamos con el correo electrónico proporcionado, hemos enviado una invitación al correo electrónico que proporcionó para que su prestamista se una a Padzilly",
    [TEXTS.MLO_ALREADY_INVITED]: "Ya hemos enviado una invitación a {{0}} usando tu correo electrónico.",
    [TEXTS.MLO_SAME_EMAIL_ERROR_TEXT]: "no debe ser el mismo que el que está asociado al MLO actual",
    [TEXTS.PASSWORDS_MUST_MATCH_ERROR_TEXT]: "Las contraseñas deben coincidir",
    [TEXTS.FIRST_NAME_ERROR_TEXT]: "Nombre Inválido, ej: Joe",
    [TEXTS.LAST_NAME_ERROR_TEXT]: "Apellido Inválido, ej: Smith",
    [TEXTS.FULL_NAME_ERROR_TEXT]: "Nombre completo Inválido, ej: Joe Smith",
    [TEXTS.MLO_WAS_REMOVED_ERROR_TEXT]: "El MLO fue eliminado",
    [TEXTS.NO_INVESTMENT_PROFILE_TEXT]: "No existe un perfil de inversión, si tienes una cuenta puedes iniciar sesión aquí",
    [TEXTS.CONTINUE_AS_GUEST_TEXT]: "Si deseas continuar como invitado puedes crear un perfil de inversión aquí",
    [TEXTS.NO_SAVED_SEARCHES_TEXT]: "Aún no tienes búsquedas guardadas",
    [TEXTS.NO_SAVED_SEARCHES_SUBTITLE_TEXT]: "Puedes ir a Casas y guardar búsquedas para ejecutarlas en cualquier momento y recibir notificaciones si quieres",
    [TEXTS.DELETE_SEARCH_TEXT]: "¿Quieres eliminar la búsqueda guardada?",
    [TEXTS.INVESTMENT_PROFILE_NO_LOAN_TEXT]: "Este cuadro contiene los límites de pago y efectivo editables por el usuario (Perfil de Inversión del Comprador - BIP(En Inglés))",
    [TEXTS.SEARCH_PRO_TEXT]: "Este cuadro muestra los tres controles deslizantes de SearchPro para préstamos Convencionales y/o FHA. Puedes ajustar y guardar tus preferencias.",
    [TEXTS.SUSCRIBE_TEXT]: "Si su oficial de préstamos no se une a Padzilly, puede obtener los mismos beneficios comprando una suscripción por solo $19,95 como pago único",
    [TEXTS.SUBSCRIPTION_DISCLAIMER_TEXT]: "Disfruta de todos los beneficios incluso si tu agente de préstamos no se une a Padzilly. Todo por una tarifa única de $19,95. Las transacciones se realizan con Stripe, Padzilly no almacena ningún dato sobre tu método de pago",
    [TEXTS.TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT]: "En la pantalla se muestra un impuesto mínimo alternativo utilizado para calificar.",
    [TEXTS.CANT_INVITE_PADZILLY_MLO_TEXT]: "No se puede invitar a este prestamista",
    [TEXTS.MLO_NOT_ACTIVE_TEXT]: "Este prestamista a pesar de estar conectado a Padzilly no está activo, por lo tanto no puedes vincularlo como tu prestamista, puedes invitar a otro prestamista o contactar a soporte para más información",
    [TEXTS.GOOD_NEWS_TEXT]: "Buenas noticias: ¡Lanzaremos cobertura a nivel nacional este enero!",
    [TEXTS.CHECK_MAP_TEXT]: "Consulte nuestro mapa interactivo a continuación para conocer la cobertura actual en su área",
    [TEXTS.HAVE_COVERAGE_TEXT]: "Si tenemos cobertura en tu zona, intenta ajustar tus filtros de búsqueda por habitaciones, características, tipo de propiedad, etc.",
    [TEXTS.HAVE_NOT_COVERAGE_TEXT]: "Si aún no tenemos cobertura en tu área, aún puedes buscar e importar propiedades desde otros sitios inmobiliarios. ",
    [TEXTS.OPEN_FROM_EMAIL_TEXT]: "Esta propiedad fue abierta desde un correo de búsqueda guardada, si el correo no es suyo es posible que vea valores diferentes en el pago y efectivo necesario ya que puede tener un perfil de inversión diferente",
    //LANDING PAGE
    [TEXTS.WE_DIDNT_FIND_PROPS_TEXT]: "No encontramos ninguna propiedad cerca de usted, puede iniciar una nueva búsqueda",
    [TEXTS.INFO_TEXT_ONE_TEXT]: "Solo Padzilly se concentra rápidamente en su casa utilizando el pago mensual deseado y el efectivo disponible para filtrar todas las casas y encontrar aquellas que se ajusten a su presupuesto.",
    [TEXTS.INFO_ONE_POINT_ONE_TEXT]: "Establezca su propio límite de pago e inversión, o cargue su calificación de préstamo de prestamista",
    [TEXTS.INFO_ONE_POINT_TWO_TEXT]: "Vea solo casas que se ajusten a sus requisitos o aprobación previa del prestamista",
    [TEXTS.INFO_ONE_POINT_THREE_TEXT]: "Cifras de pago y efectivo de alta precisión",
    [TEXTS.OFFER_LIKE_A_PRO_TEXT]: "Padzilly es la única plataforma que le ayuda a estructurar su oferta para obtener la mejor oferta y adaptar una propiedad a la aprobación de su préstamo.",
    [TEXTS.INFO_TWO_POINT_ONE_TEXT]: "Ajuste el precio, la concesión del vendedor y el pago inicial en segundos",
    [TEXTS.INFO_TWO_POINT_TWO_TEXT]: "Pruebe infinitos escenarios financieros con facilidad",
    [TEXTS.INFO_TWO_POINT_THREE_TEXT]: "Estructura tu mejor trato y haz ofertas con confianza, tal como lo hacen los profesionales",
    [TEXTS.LOAN_APPROVAL_TEXT]: "Pídale a su prestamista que cargue la aprobación de su préstamo y Padzilly se asegurará de que cada listado coincida con su préstamo preaprobado",
    [TEXTS.INFO_THREE_POINT_ONE_TEXT]: "Elimina las conjeturas",
    [TEXTS.INFO_THREE_POINT_TWO_TEXT]: "Vea solo las casas para las que realmente califica",
    [TEXTS.INFO_THREE_POINT_THREE_TEXT]: "Pagos a nivel de prestamista y cifras de efectivo",
    [TEXTS.INFO_THREE_POINT_FOUR_TEXT]: "Obtenga cartas de aprobación de propiedad instantáneas y términos de oferta",
    [TEXTS.FOOTER_TEXT]: "Para una mejor experiencia puedes descargar la aplicación desde Google Play o AppStore",
    [TEXTS.LENDER_NOT_ACTIVE_TEXT]: "Su prestamista no está activo, por favor contáctelo",
    //SCHOOLS
    [TEXTS.SCHOOLS_DISCLAIMER_TEXT]: "La calificación GreatSchools ayuda a los padres a comparar escuelas dentro de un estado basándose en una variedad de indicadores de calidad escolar y proporciona una imagen útil de la eficacia con la que cada escuela atiende a todos sus estudiantes. Las calificaciones están en una escala de 1 (por debajo del promedio) a 10 (por encima del promedio) y puede incluir puntajes de exámenes, preparación para la universidad, progreso académico, cursos avanzados, equidad, disciplina y datos de asistencia. También recomendamos a los padres que visiten las escuelas, consideren otra información sobre el desempeño y los programas escolares y consideren las necesidades familiares como parte del proceso de selección",
    //SEARCH PRO
    [TEXTS.SELLER_REBATE_ONLY_LAP_TEXT]: "El reembolso al vendedor solo está disponible una vez que su prestamista carga su aprobación o precalificación. Invite a su prestamista a cargarlo ahora y desbloquear esta función",
    //LOADING PROPERTIES
    [TEXTS.LOADING_PROPERTIES_ONE_TEXT]: "Sacando cuentas como un chef picando verduras... ¡Tu receta para la casa perfecta está casi lista! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_TWO_TEXT]: "Sacando cuentas como un DJ mezclando ritmos... ¡La pista de la casa perfecta está a punto de sonar! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_THREE_TEXT]: "Sacando cuentas como una ardilla guardando bellotas... ¡Recopilando las casas adecuadas para ti! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_FOUR_TEXT]: " Sacando cuentas como un barista espumando leche... ¡Tu casa personalizada está casi lista! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_FIVE_TEXT]: "Sacando cuentas como un maestro de rompecabezas... ¡Encajando las piezas de tu hogar perfecto! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_SIX_TEXT]: " Sacando cuentas como un escritor buscando la palabra perfecta... ¡Tu historia de hogar está casi escrita! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_SEVEN_TEXT]: "Sacando cuentas como un escultor tallando mármol... ¡Tu obra maestra de hogar está tomando forma! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_EIGHT_TEXT]: " Sacando cuentas como un panadero amasando masa... ¡La casa perfecta está a punto de levantarse! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_NINE_TEXT]: "Sacando cuentas como un gamer subiendo de nivel... ¡Tu victoria de hogar está en el horizonte! Por favor, espere",
    //TRIP
    [TEXTS.TRIP_HOME_SEARCH_GOALS_PAYMENT_TEXT]: "Su objetivo de pago hipotecario mensual nos ayuda a mostrarle las casas que puede pagar según sus límites, antes de que cargue una aprobación real del prestamista. ¿No estás seguro? Piense con cuánto se siente cómodo pagando cada mes por su casa, incluido el capital, intereses, impuestos y seguro. Siempre puedes ajustar esto más tarde.",
    [TEXTS.TRIP_HOME_SEARCH_GOALS_CASH_TEXT]: "Esta es la cantidad total de dinero que tiene disponible para cubrir el pago inicial, los costos de cierre y otros gastos iniciales. Si no está seguro, ingrese la cantidad que ha ahorrado o planea usar para la compra de su casa. Usamos esto para mostrarle casas que se ajusten a su presupuesto.",
    [TEXTS.TRIP_PAYMENT_BTN_TEXT]: "Presione este botón para mostrar solo casas con pagos mensuales que se encuentren dentro de su límite de pago objetivo",
    [TEXTS.TRIP_CASH_BTN_TEXT]: "Presione este botón para mostrar solo casas que se ajusten a su límite de efectivo disponible",
    [TEXTS.TRIP_PAYMENT_CASH_BTN_TEXT]: "Al presionar los botones 'Filtrar por pago' y 'Filtrar por efectivo necesario', solo verá casas que se ajusten completamente a sus límites de pago y efectivo",
    [TEXTS.TRIP_CONVENTIONAL_LOAN_TEXT]: "Vea el pago mensual estimado y el efectivo necesario como si estuviera financiando esta casa con un préstamo convencional. Esta estimación utiliza el pago inicial y las tasas de interés típicas de los préstamos convencionales",
    [TEXTS.TRIP_FHA_LOAN_TEXT]: "Vea el pago mensual estimado y el efectivo necesario como si estuviera financiando esta casa con un préstamo de la FHA. Esta estimación incluye términos específicos de la FHA, como pagos iniciales más bajos y prima de seguro hipotecario (MIP)",
    [TEXTS.TRIP_SEARCH_PRO_TEXT]: "Utilice BúsquedaPro para aplicar filtros avanzados y encontrar viviendas que se ajusten a sus parámetros financieros y de préstamo, incluidos los límites de pago y efectivo necesarios. Ajuste el precio de venta, la contribución del vendedor o aumente su pago inicial para refinar los resultados de su búsqueda",
    [TEXTS.TRIP_BED_TEXT]: "Filtre rápidamente casas por el número de dormitorios. Vea casas que se adapten a sus necesidades de espacio y estilo de vida",
    [TEXTS.TRIP_BATH_TEXT]: "Filtra rápidamente casas por número de baños. Encuentra propiedades con el número de baños adecuado para tu comodidad",
    [TEXTS.TRIP_SAVE_SEARCH_TEXT]: "Guarda tus búsquedas favoritas para volver a realizarlas en cualquier momento y recibir alertas de propiedades perfectas para ti",
    [TEXTS.TRIP_FILTERS_TEXT]: "Utilice filtros avanzados para limitar su búsqueda por tamaño del lote, año de construcción, tamaño de la casa, tipo de propiedad y más. Personaliza tu búsqueda para encontrar la casa perfecta",
    [TEXTS.TRIP_PROPERTY_ESTIMATED_PAYMENT_TEXT]: "Este es el pago mensual total estimado para esta vivienda, incluyendo la hipoteca, los impuestos y el seguro. Si la casilla es verde, el pago está dentro de su límite objetivo (Perfil de Inversión del Comprador - BIP). Si la casilla es roja, el pago excede su límite. Puedes ajustar tus límites o explorar otras opciones de financiación",
    [TEXTS.TRIP_PROPERTY_CASH_NEEDED_TEXT]: "Este es el efectivo total estimado necesario para comprar esta casa, incluido el pago inicial, los costos de cierre y otros cargos. Un cuadro verde significa que este monto está dentro de su límite de fondos disponibles (BIP). Un cuadro rojo significa que el efectivo necesario excede su límite. Puede ajustar sus fondos disponibles o negociar términos que se ajusten a su presupuesto",
    [TEXTS.TRIP_MENU_FAVORITES_TEXT]: "Vea y compare las propiedades que ha guardado. También podrás ver las búsquedas guardadas para ejecutarlas cuando quieras",
    [TEXTS.TRIP_MENU_PROFILE_TEXT]: "Administre su perfil de inversión del comprador (BIP) y edite los límites de pago y efectivo",
    [TEXTS.TRIP_INVESTMENT_PROFILE_TEXT]: "Estos son el pago mensual máximo y los fondos totales disponibles que has establecido. Puedes editarlos y guardar los cambios aquí",
    [TEXTS.TRIP_IMPORT_PROPERTIES_TEXT]: "Importe propiedades de Zillow o Realtor.com pegando aquí la URL de la página de listado de propiedades. Agregue fácilmente más casas para compararlas una al lado de la otra",
    [TEXTS.TRIP_COMPARE_PROPERTIES_TEXT]: "Seleccione hasta cinco propiedades para comparar las características físicas y las finanzas de las propiedades. Toque en cualquier lugar de la tarjeta de propiedad para seleccionar o anular la selección.",
    [TEXTS.TRIP_OFFER_PRO_TEXT]: "Puedes ajustar los siguientes valores si deseas calificar para pago y efectivo",
    [TEXTS.TRIP_SEARCH_PRO_LOANS_TEXT]: "Haga clic aquí para comparar los préstamos FHA y Convencionales. Ajuste el precio de su oferta y el pago inicial para ver el impacto en sus finanzas",
    [TEXTS.TRIP_SEARCH_PRO_OFFER_PRICE_TEXT]: "Ajuste el precio de oferta aumentando o disminuyendo un porcentaje del precio de cotización. Padzilly aplica este ajuste a todas las viviendas en su búsqueda y recalcula sus pagos estimados y el efectivo total necesario en función de este ajuste",
    [TEXTS.TRIP_SEARCH_PRO_SELLER_TEXT]: "Utilice este control deslizante para aplicar un reembolso o contribución al vendedor, reduciendo el total de efectivo necesario y el pago mensual. Se aplica a todas las viviendas en su área de búsqueda y permitirá el monto adecuado con los límites de su programa de préstamos.",
    [TEXTS.TRIP_SEARCH_PRO_SELLER_RED_TEXT]: "Debe tener una aprobación cargada para utilizar esta función",
    [TEXTS.TRIP_SEARCH_PRO_DOWN_PAYMENT_TEXT]: "Aumente su pago inicial para reducir su pago mensual y ajuste sus resultados de búsqueda en consecuencia. Esto se aplica a todos los listados en su búsqueda",
    [TEXTS.TRIP_LOAN_ORIGINATOR_TEXT]: "Haga clic aquí para invitar a su prestamista a cargar la aprobación o precalificación de su préstamo y desbloquear todas las funciones de Padzilly, incluido el reembolso/contribución del vendedor",
    //ADD LOAN(BUYER)
    [TEXTS.TRUE_PDF_TEXT]: 'Este es el tipo de archivo que necesitas cargar para que Padzilly pueda leerlo. Se crea un PDF con capacidad de búsqueda eligiendo la opción "GUARDAR EN PDF" desde su software de originación de préstamos',
    [TEXTS.IMAGE_PDF_TEXT]: 'Padzilly no puede leer este tipo de PDF. No es legible por el software hasta que se convierte a un formato con capacidad de búsqueda. Los archivos PDF de solo imágenes generalmente se crean eligiendo la opción "IMPRIMIR EN PDF" y no son adecuados para cargar',
    [TEXTS.WARNING_TEXT]: "La información del prestatario principal, como el nombre y los datos de contacto, no se puede modificar una vez que se ha creado el préstamo",
    [TEXTS.LOAN_FORM_TEXT]: "Esta sección debe incluir créditos de agentes inmobiliarios o fondos DPA para programas SIN pago mensual. El crédito del vendedor NO debe incluirse",
    [TEXTS.COMPLETE_BYUER_INFO_TEXT]: "Corrija los errores de información del comprador y complete todos los datos requeridos antes de guardar el préstamo",
    [TEXTS.COMPLETE_CALCULATIONS_INFO_TEXT]: "Corrija los errores de cálculo y complete todos los datos requeridos antes de guardar el préstamo",
}