function getApiRootPath() {
  if (window.location.origin.startsWith("https://qa")) {
    return "https://qa-api.padzilly.app";
  }
  if (
    window.location.origin.startsWith("https://www.") ||
    window.location.origin.startsWith("https://padzilly.com") ||
    window.location.origin.startsWith("https://padzilly.app")
  ) {
    return "https://api.padzilly.app";
  }
  return "https://qa-api.padzilly.app";
  //return "https://localhost:7045";
}

export const BASE_URL = getApiRootPath();

//Endpoints//
//AUTH//
export const LOGIN_AS_GUEST = `${BASE_URL}/api/Auth/access-as-guest`;
export const SING_UP = `${BASE_URL}/api/Auth/sign-up`;
export const UPDATE_GUEST_USER = `${BASE_URL}/api/Auth/update-guest-account`;
export const VERIFICATION_USER = `${BASE_URL}/api/Auth/verify-user`;
export const RESEND_VERIFICATION_CODE = `${BASE_URL}/api/Auth/resend-otp`;
export const GET_USER_ACCOUNT = `${BASE_URL}/api/Auth/get-account`;
export const LOG_OUT = `${BASE_URL}/api/Auth/logout`;
export const LOG_IN = `${BASE_URL}/api/Auth/login`;
export const SEND_PASSWORD_OTP = `${BASE_URL}/api/Auth/sendpwdotp`;
export const CHANGE_RESET_PASSWORD = `${BASE_URL}/api/Auth/change-reset-pwd`;

//PROPERTIES//
export const SEARCH_LOCATION = `${BASE_URL}/api/Search`;
export const LISTED_PROPERTIES = `${BASE_URL}/api/ListedProperties`;
export const GET_PROPERTY = `${BASE_URL}/api/ListedProperties/`;
export const GET_SHARED_PROPERTY = `${BASE_URL}/api/ListedProperties/get-simple-property-by-id`;
export const SAVE_PROPERTY_AS_FAVORITE = `${BASE_URL}/api/ListedProperties/save-property`;
export const GET_FAVORITES = `${BASE_URL}/api/ListedProperties/get-favorite-properties`;
export const SAVE_FAVORITE = `${BASE_URL}/api/ListedProperties/save-fav-property`;
export const SAVE_EXTERNAL_PROPERTY = `${BASE_URL}/api/ListedProperties/save-external-property`;
export const GET_NEAR_PROPERTIES = `${BASE_URL}/api/ListedProperties`;

//INVESTMENT PROFILE//
export const GET_INVESTMENT_PROFILE = `${BASE_URL}/api/InvestmentProfile`;
export const UPDATE_INVESTMENT_PROFILE = `${BASE_URL}/api/InvestmentProfile/update-profile`;
export const CREATE_INVESTMENT_PROFILE = `${BASE_URL}/api/InvestmentProfile/`;

//MLO
export const GET_MLO_BY_EMAIL = `${BASE_URL}/api/Mlo`;
export const GET_MLO_BY_ID = `${BASE_URL}/api/Mlo/get-by-id`;

//LOANS
export const GET_LOAN_BY_USER_EMAIL = `${BASE_URL}/api/Loans/get-by-buyer-email`;
export const GET_LOAN_BY_ID = `${BASE_URL}/api/Loans/`;
export const PARSER_PDF = `${BASE_URL}/api/Loans/pdf-parser`;
export const ADD_BUYER_LOAN = `${BASE_URL}/api/Loans/add-buyer-loan`;
export const EDIT_BUYER_LOAN = `${BASE_URL}/api/Loans/edit-buyer-loan`;

//BUYER
export const GET_LETTER = `${BASE_URL}/api/Buyer/letter`;
export const OFFER_PRO_LETTER = `${BASE_URL}/api/Buyer/offer-letter`;
export const BUY_SUBSCRIPTION = `${BASE_URL}/api/Buyer/buy-subscription`;
export const INVITE_MLO = `${BASE_URL}/api/Buyer/mlo-new-invitation`;

//PROXY
export const PROXY_URL = `${BASE_URL}/api/Proxy`;

//LANDING PAGE
export const LANDING_PAGE_ELEMENTS = `${BASE_URL}/api/Landing`;

//SAVED SEARCHES
export const GET_SAVED_SEARCHES = `${BASE_URL}/api/SavedSearch/get-saved-searches`;
export const CREATE_SAVED_SEARCH = `${BASE_URL}/api/SavedSearch/create-saved-search`;
export const SET_ALERTS = `${BASE_URL}/api/SavedSearch/set-alerts`;
export const REMOVE_SAVED_SEARCH = `${BASE_URL}/api/SavedSearch/delete-saved-search`;
