import {
    Box,
    Container,
    Divider,
    List,
    ListItem,
    IconButton,
    Drawer
} from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Link from "@mui/material/Link";
import {
    CREATE_ACCOUNT_ROUTE,
    LANDING_PAGE
} from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import {
    BORROWER_ROLE,
    GUEST_ROLE,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    APP_STORE as APP_STORE_IMG,
    GOOGLE_PLAY as GOOGLE_PLAY_IMG,
} from "../../../constants/constants";
import SessionActions from "../../Login/SessionActions";
import BaseChangeLanguage from "../../BaseComponents/BaseChangeLanguage";
import { useTranslation } from 'react-i18next';
import {
    ALL_RIGHTS_RESERVED_LABEL,
    LENDERS_LABEL,
    PARTNERS_LABEL,
    SIGN_UP_LABEL
} from "../../../i18n/i18nLabel";
import {
    APP_STORE,
    GOOGLE_PLAY,
    LENDERS,
    PARTNER_PAGE
} from "../../../constants/urls";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LogoNoWhite from "../../Images/LogoNoWhite";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { FOOTER_TEXT } from "../../../i18n/i18nText";

export default function TopBar() {

    const { t } = useTranslation();

    const history = useHistory();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const [mobileOpen, setMobileOpen] = useState(false);

    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const drawerMenu = (
        <Box sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%"
        }}>
            <Box>
                <Divider
                    sx={{
                        marginTop: "15px",
                        '&:before': {
                            background: 'white',
                        },
                        '&:after': {
                            background: 'white',
                        },
                    }}
                >
                    <LogoNoWhite width="90px" height="20px" />
                </Divider>
                <List>
                    <ListItem
                        onClick={handleDrawerClose}
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <Link onClick={() => { window.open(LENDERS) }}
                            style={{
                                textDecoration: "none",
                                color: PRIMARY_COLOR,
                                textTransform: "uppercase",
                            }}
                        >
                            <Box sx={{
                                color: "inherit",
                                '&:hover': {
                                    color: SECONDARY_COLOR,
                                },
                                '&:active': {
                                    color: SECONDARY_COLOR,
                                },
                            }}>
                                <span>{t(LENDERS_LABEL)}</span>
                            </Box>
                        </Link>
                    </ListItem>
                    <ListItem
                        onClick={handleDrawerClose}
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <Link onClick={() => { window.open(PARTNER_PAGE) }}
                            style={{
                                textDecoration: "none",
                                color: PRIMARY_COLOR,
                                textTransform: "uppercase",
                            }}
                        >
                            <Box sx={{
                                color: "inherit",
                                '&:hover': {
                                    color: SECONDARY_COLOR,
                                },
                                '&:active': {
                                    color: SECONDARY_COLOR,
                                },
                            }}>
                                <span>{t(PARTNERS_LABEL)}</span>
                            </Box>
                        </Link>
                    </ListItem>
                    {localStorage.getItem("profile_role") === GUEST_ROLE || !localStorage.getItem("profile_role")
                        ?
                        <ListItem
                            onClick={handleDrawerClose}
                            sx={{ justifyContent: "flex-end" }}
                        >
                            <Link onClick={() => history.push(CREATE_ACCOUNT_ROUTE)}
                                style={{
                                    textDecoration: "none",
                                    color: PRIMARY_COLOR,
                                    textTransform: "uppercase",
                                }}
                            >
                                <Box sx={{
                                    color: "inherit",
                                    '&:hover': {
                                        color: SECONDARY_COLOR,
                                    },
                                    '&:active': {
                                        color: SECONDARY_COLOR,
                                    },
                                }}>
                                    <span>{t(SIGN_UP_LABEL)}</span>
                                </Box>
                            </Link>
                        </ListItem>
                        : null
                    }
                </List>
            </Box>
            <Box sx={{ marginBottom: "20px", padding: "0px 10px" }}>
                <p
                    style={{
                        fontSize: ".7rem",
                        margin: "0px",
                        width: "100%",
                        textAlign: "center",
                        marginTop: "15px"
                    }}
                >
                    {t(FOOTER_TEXT)}
                </p>
                <Box sx={{
                    display: "flex",
                    marginTop: "5px",
                    marginBottom: "5px",
                    justifyContent: "center"
                }}>
                    <Box
                        sx={{
                            width: "110px",
                            height: "auto",
                            cursor: "pointer",
                        }}
                        onClick={() => window.open(GOOGLE_PLAY, "_blank")}
                    >
                        {
                            <img alt="Google Play" src={GOOGLE_PLAY_IMG} width={"110px"} />
                        }
                    </Box>
                    <Box
                        sx={{
                            width: "125px",
                            height: "auto",
                            cursor: "pointer",
                        }}
                        onClick={() => window.open(APP_STORE, "_blank")}
                    >
                        {
                            <img
                                alt="App Store"
                                src={APP_STORE_IMG}
                                width={"125px"}
                                height={"29px"}
                                style={{ display: "block", marginTop: "7px" }}
                            />
                        }
                    </Box>
                </Box>
                <p
                    style={{
                        margin: "0px",
                        fontSize: ".8rem",
                        textAlign: "center"

                    }}>
                    {t(ALL_RIGHTS_RESERVED_LABEL)}
                </p>
            </Box>
        </Box>
    );

    const drawerWidth = 240;

    const container = window.document.body;

    const topBar = (
        <Box
            sx={{
                background: `white !important`,
                boxShadow: "none",
                borderBottom: `solid 4px white`,
                height: "54px"
            }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "54px !important",
                alignItems: "center",
            }}>
                <Box sx={{ marginLeft: ".5rem" }}>
                    <ButtonBase onClick={() => history.push(LANDING_PAGE)}>
                        <LogoNoWhite width={isSmOrSmaller ? "125px" : "135px"} height={isSmOrSmaller ? "25px" : "30px"} />
                    </ButtonBase>
                </Box>
                {!isSmOrSmaller
                    ? <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        fontSize: ".8rem"
                    }}>
                        <Link
                            style={{
                                textDecoration: "none",
                                marginRight: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() => window.open(LENDERS, "_blank")}
                        >
                            <Box
                                sx={{
                                    height: '54px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    color: "black",
                                    transition: 'color 0.2s ease-out',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        height: '2px',
                                        width: '0%',
                                        backgroundColor: PRIMARY_COLOR,
                                        transition: 'width 0.2s ease-out',
                                    },
                                    '&:hover::after': {
                                        width: '100%',
                                    },
                                    '&:hover': {
                                        fontWeight: "bold",
                                    },
                                    '&:active': {
                                        '&::after': {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                {t(LENDERS_LABEL)}
                            </Box>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                marginRight: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() => window.open(PARTNER_PAGE, "_blank")}
                        >
                            <Box
                                sx={{
                                    height: '54px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    color: "black",
                                    transition: 'color 0.2s ease-out',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        height: '2px',
                                        width: '0%',
                                        backgroundColor: PRIMARY_COLOR,
                                        transition: 'width 0.2s ease-out',
                                    },
                                    '&:hover::after': {
                                        width: '100%',
                                    },
                                    '&:hover': {
                                        fontWeight: "bold",
                                    },
                                    '&:active': {
                                        '&::after': {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                {t(PARTNERS_LABEL)}
                            </Box>
                        </Link>
                        {localStorage.getItem("profile_role") === GUEST_ROLE || !localStorage.getItem("profile_role")
                            ? <Link
                                style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: SECONDARY_COLOR,
                                }}
                                onClick={() => history.push(CREATE_ACCOUNT_ROUTE)}
                            >
                                <Box sx={{
                                    height: '54px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    color: "black",
                                    transition: 'color 0.2s ease-out',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        height: '2px',
                                        width: '0%',
                                        backgroundColor: PRIMARY_COLOR,
                                        transition: 'width 0.2s ease-out',
                                    },
                                    '&:hover::after': {
                                        width: '100%',
                                    },
                                    '&:hover': {
                                        fontWeight: "bold",
                                    },
                                    '&:active': {
                                        '&::after': {
                                            width: '100%',
                                        },
                                    },
                                }}>
                                    {t(SIGN_UP_LABEL)}
                                </Box>
                            </Link>
                            : null
                        }
                        <BaseChangeLanguage />
                        {
                            localStorage.getItem("profile_role") === BORROWER_ROLE
                                ? <SessionActions />
                                : null
                        }
                    </Box>
                    : <Box sx={{ display: "flex", alignItems: "center" }}>
                        <BaseChangeLanguage />
                        {
                            localStorage.getItem("profile_role") === BORROWER_ROLE
                                ? <SessionActions />
                                : null
                        }
                        <IconButton
                            size="medium"
                            color="inherit"
                            sx={{ display: { sm: "none" } }}
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon fontSize='medium' />
                        </IconButton>
                    </Box>}
            </Box>
        </Box>
    );

    return isSmOrSmaller
        ? (
            <Box sx={{
                background: "white",
            }}>
                {topBar}
                {isSmOrSmaller ? <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="right"
                        open={mobileOpen}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'white' },
                        }}
                    >
                        {drawerMenu}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },

                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawerMenu}
                    </Drawer>
                </Box>
                    : null
                }
            </Box>
        )
        : (
            <Box sx={{
                background: "white",
            }}>
                <Container sx={{
                    background: "white",
                }}>
                    {topBar}
                </Container>
            </Box>
        )
}