import { Box } from "@mui/material";
import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import Link from "@mui/material/Link";
import {
    GET_INVESTMENT_PROFILE,
    GET_LANGUAGE,
} from "../../constants/store/getters";
import { useStore } from "../../hooks/store/store";
import {
    MIN_TAX,
    MIN_TAX_ES,
    PAYMENT_EXPLAIN,
    PAYMENT_EXPLAIN_ES
} from "../../constants/urls";
import { useTranslation } from 'react-i18next';
import {
    PAYMENT_BREAKDOWN_FIRST_TEXT,
    PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT,
    PAYMENT_BREAKDOWN_SECOND_TEXT,
    PAYMENT_BREAKDOWN_TITLE_TEXT,
    TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT
} from "../../i18n/i18nText";
import {
    ASSOCIATION_FEE_LABEL,
    INSURANCE_LABEL,
    LEARN_MORE_LABEL,
    MONTHLY_LABEL,
    MORTGAGE_LABEL,
    PAYMENT_NOT_INCLUDE_LABEL,
    PROPERTY_TAX_LABEL
} from "../../i18n/i18nLabel";
import { setCurrencyValue } from "../../utils/functions";
import { primaryTheme } from "../themes/themes";
import { ThemeProvider } from '@mui/material/styles';
import { ES_US } from "../../constants/constants";
import Alert from '@mui/material/Alert';

export default function PaymentBreakdown(props) {

    const { state } = useStore();

    const { t } = useTranslation();

    const investment_profile = state[GET_INVESTMENT_PROFILE];

    const pAndI = Math.ceil(props.property.principalAndInt);
    const insurance = Math.ceil(props.property.homeIns);
    const taxes = Math.ceil(props.property.taxes);
    const mortageIns = Math.ceil(props.property.monthlyPMI);
    const total = Math.ceil((pAndI + insurance + taxes + mortageIns));


    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <BaseDialog
            {...props}
        >
            <BaseDialogHeader
                close={() => { props.onClose(); }}
            >
                {t(PAYMENT_BREAKDOWN_TITLE_TEXT)}{" "}
                <ThemeProvider theme={primaryTheme}>
                    <Link
                        className="cursor"
                        color="primary"
                        onClick={(e) => {
                            handleClick(e);
                            window.open(state[GET_LANGUAGE] === ES_US ? PAYMENT_EXPLAIN_ES : PAYMENT_EXPLAIN, '_blank');
                        }}
                    >
                        {t(LEARN_MORE_LABEL)}
                    </Link>
                </ThemeProvider>
            </BaseDialogHeader>
            <BaseDialogBody>
                <div style={{ padding: "8px" }}>
                    {props?.property?.taxAnnualAmount !== props?.property?.orgPropTaxAnnualAmount
                        ? <Alert severity="info" sx={{ marginBottom: "10px" }}>
                            <p style={{ margin: "0px", fontSize: ".85rem" }}>
                                {t(TAXES_BREAKDOWN_PAYMENT_OFFER_PRO_TEXT)}&nbsp;
                                <ThemeProvider theme={primaryTheme}>
                                    <Link
                                        className="cursor"
                                        color="primary"
                                        onClick={() => window.open(state[GET_LANGUAGE] === ES_US ? MIN_TAX_ES : MIN_TAX, "_blank")}
                                    >
                                        {t(LEARN_MORE_LABEL)}
                                    </Link>
                                </ThemeProvider>
                            </p>
                        </Alert>
                        : null
                    }
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>P&I</td>
                                    <td style={{ textAlign: "right" }}>{setCurrencyValue(pAndI)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(INSURANCE_LABEL)}</td>
                                    <td style={{ textAlign: "right" }}>{setCurrencyValue(insurance)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(PROPERTY_TAX_LABEL) + "/" + t(MONTHLY_LABEL)}</td>
                                    <td style={{ textAlign: "right" }}>{setCurrencyValue(taxes)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(MORTGAGE_LABEL)}</td>
                                    <td
                                        style={{ borderBottom: "solid 1px grey", textAlign: "right" }}
                                    >{setCurrencyValue(mortageIns)}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td style={{ fontWeight: "bold", textAlign: "right" }}>${setCurrencyValue(total)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props.property.associationFee ? <p style={{ fontWeight: "bold" }}>{t(PAYMENT_NOT_INCLUDE_LABEL)} ${setCurrencyValue(Math.ceil(props.property.associationFee))} {t(ASSOCIATION_FEE_LABEL)}</p> : null}
                    </Box>
                    {
                        investment_profile?.shouldShowDisclosureRate ?
                            <p>
                                {t(PAYMENT_BREAKDOWN_FIRST_TEXT)}
                            </p>
                            : <p>
                                {t(PAYMENT_BREAKDOWN_SECOND_TEXT)}<strong>{t(PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT)}</strong>
                            </p>
                    }
                </div>
            </BaseDialogBody>
        </BaseDialog>
    );
}