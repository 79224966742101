import { Box } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import {
    STATUS_ACTIVE,
    STATUS_COMING_SOON,
    STATUS_PENDING,
    STATUS_UNDER_CONTRACT
} from "../../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    COMING_SOON_LABEL,
    FOR_SALE_LABEL,
    PENDING_LABEL,
    UNDER_CONTRACT_LABEL
} from "../../../i18n/i18nLabel";

export default function PropertyStatus({ status }) {

    const { t } = useTranslation();

    return (
        <Box>
            {status === STATUS_PENDING
                ? <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircleIcon style={{
                        color: "yellow",
                        fontSize: "small",
                    }} />
                    &nbsp;
                    <span>{t(PENDING_LABEL)}</span>
                </Box>
                : status === STATUS_UNDER_CONTRACT
                    ? <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CircleIcon style={{
                            color: "red",
                            fontSize: "small",
                        }} />
                        &nbsp;
                        <span style={{ marginRight: "5px" }}>{t(UNDER_CONTRACT_LABEL)}</span>
                    </Box>
                    : status === STATUS_COMING_SOON
                        ? <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CircleIcon style={{
                                color: "blue",
                                fontSize: "small",
                            }} />
                            &nbsp;
                            <span style={{ marginRight: "5px" }}>{t(COMING_SOON_LABEL)}</span>
                        </Box>
                        : status === STATUS_ACTIVE
                            ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CircleIcon style={{
                                    color: "green",
                                    fontSize: "small",
                                }} />
                                &nbsp;
                                <span style={{ marginRight: "5px" }}>{t(FOR_SALE_LABEL)}</span>
                            </Box>
                            : null
            }
        </Box>
    );
}