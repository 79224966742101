import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ABOUT_THIS_HOME_LABEL,
  LISTED_BY_LABEL,
} from "../../../i18n/i18nLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { lazy } from "react";

const DisclaimerBtnDialog = lazy(() => import('../../Dialogs/DisclaimerBtnDialog'));

export default function AboutThisHome({ property }) {
  const { t } = useTranslation();

  const theme = useTheme();

  const isSmOrSmaller = useMediaQuery(theme.breakpoints.only("xs"));

  const getFormattedEmails = (emails) => {
    if (emails) {
      return emails.split(";");
    } else {
      return [""];
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned =
      phoneNumberString != null
        ? ("" + phoneNumberString).replace(/\\D/g, "")
        : phoneNumberString;
    if (phoneNumberString?.length === 10) {
      var match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    }
    return phoneNumberString;
  };

  return (
    <Box
      sx={{
        borderBottom: "solid 1px grey",
        paddingBottom: "20px",
      }}
    >
      <Box sx={{ padding: "4px" }}>
        <h3>{t(ABOUT_THIS_HOME_LABEL)}</h3>
        <p>{property?.remarks}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: isSmOrSmaller ? "center" : "space-between",
          alignItems: "start",
          padding: "4px",
          flexDirection: isSmOrSmaller ? "column" : "row",
        }}
      >
        {property?.listAgentFullName ||
          property?.listAgentEmail ||
          property?.listAgentPhone ||
          property?.brokerageAtribution ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: "dimgray" }}>{t(LISTED_BY_LABEL)}</span>
            <span style={{ fontWeight: "bold", color: "dimgray" }}>
              {property?.listAgentFullName}
            </span>
            {getFormattedEmails(property?.listAgentEmail).map((item) => (
              <span key={item} style={{ fontWeight: "bold", color: "dimgray" }}>
                {item}
              </span>
            ))}
            <span style={{ fontWeight: "bold", color: "dimgray" }}>
              {formatPhoneNumber(property?.listAgentPhone)}
            </span>
            <span style={{ fontWeight: "bold", color: "dimgray" }}>
              {property?.brokerageAtribution}
            </span>
          </Box>
        ) : null}
        <Box
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          <Box
            gap={1}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {property?.mlsLogoURl
              ? <img
                alt="MLS Logo"
                style={{ maxHeight: "2.5rem", maxWidth: "5rem" }}
                src={property?.mlsLogoURl}
                loading="lazy"
              />
              : null}
            {property?.brokerLogoURL ? (
              <img
                alt="MLS Logo"
                style={{ maxHeight: "2.5rem", maxWidth: "5rem" }}
                src={property?.brokerLogoURL}
                loading="lazy"
              />
            ) : null}
          </Box>
          {property?.disclaimer ? (
            <DisclaimerBtnDialog property={property} />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
