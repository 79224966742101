import { Alert, Box } from "@mui/material";
import {
    NO_PROPERTY,
} from "../../constants/constants";
import NoPropertyDetails from "./NoPropertyDetails";
import PropertyDetailSkeleton from "./PropertyDetailSkeleton";
import {
    useState,
    useEffect,
} from 'react';
import getSharedPropertyUrl,
{
    setCurrencyValue
} from "../../utils/functions";
import MetaTags from "../../MetaTags";
import Container from "@mui/material/Container";
import SchoolsList from "./PropertyDetails/SchoolsList";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import DetailsTopBar from "./PropertyDetails/DetailsTopBar";
import AboutThisHome from "./PropertyDetails/AboutThisHome";
import DetailsInfo from "./PropertyDetails/DetailsInfo";
import DetailsPhotos from "./PropertyDetails/DetailsPhotos";
import DetailsHome from "./PropertyDetails/DetailsHome";
import TopBar from "../LandingPage/TopBar/TopBar";
import { useTranslation } from "react-i18next";
import { LOG_IN_LABEL, SIGN_UP_LABEL, TRY_PADZILLY_LABEL } from "../../i18n/i18nLabel";
import BaseBtn from "../BaseComponents/BaseBtn";
import { CREATE_ACCOUNT_ROUTE, LOGIN_ROUTE } from "../../constants/routes";
import { useHistory } from "react-router-dom";

export default function ViewDetailsSharedProperty(props) {

    const history = useHistory();

    const [property, setProperty] = useState(props.property);

    useEffect(() => { setProperty(props.property) }, [props.property]);

    const { t } = useTranslation();

    useEffect(() => {
        if (localStorage.getItem("property_details")) {
            localStorage.removeItem("property_details");
        }
        window.scrollTo(0, 0);
    }, []);

    const [images, setImages] = useState(property?.media ?? []);

    useEffect(() => {
        setImages(property?.media ?? []);
    }, [props.property]);

    const metaDescription = () => {
        const price = "Price: $" + setCurrencyValue(property?.price);
        const address = property?.fullAddress ? `${property?.fullAddress}` : "";
        return price + " " + address;
    }

    return (
        <Box >
            <TopBar />
            {
                property === NO_PROPERTY ?
                    <NoPropertyDetails />
                    : property === null ?
                        <PropertyDetailSkeleton sharedProperty={true} />
                        :
                        <Box>
                            <Container>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}>
                                    <Alert
                                        severity="warning"
                                        sx={{ margin: "10px 0px" }}
                                        icon={false}
                                    >
                                        <h3>{t(TRY_PADZILLY_LABEL)}</h3>
                                        <Box>
                                            <BaseBtn
                                                label={t(SIGN_UP_LABEL)}
                                                onClick={() => {
                                                    history.push(CREATE_ACCOUNT_ROUTE);
                                                }}
                                                style={{
                                                    marginRight: "8px",
                                                    marginTop: "8px"
                                                }}
                                                color="secondary"
                                            />
                                            <BaseBtn
                                                label={t(LOG_IN_LABEL)}
                                                variant="outlined"
                                                style={{
                                                    marginRight: "8px",
                                                    marginTop: "8px"
                                                }}
                                                onClick={() => {
                                                    history.push(LOGIN_ROUTE);
                                                }}
                                                color="secondary"
                                            />
                                        </Box>
                                    </Alert>
                                    <DetailsPhotos images={images} property={property} />
                                    <DetailsHome property={property} />
                                    <DetailsInfo property={property} />
                                    <AboutThisHome property={property} />
                                    <SchoolsList schools={property?.schools} />
                                </Box>
                                <MetaTags metas={[
                                    {
                                        type: "url",
                                        content: getSharedPropertyUrl(property?.propertyId)
                                    },
                                    {
                                        type: "title",
                                        content: "Padzilly Property"
                                    },
                                    {
                                        type: "image",
                                        content: images[0]?.mediaUrl ?? ""
                                    },
                                    {
                                        type: "description",
                                        content: metaDescription()
                                    },
                                    /* {
                                         title: (property?.fullAddress ? property?.fullAddress.trim() + ", " : "") + property?.city + ", " + property?.state + " | ::Padzilly::"
                                     }*/
                                ]} />
                            </Container>
                            <LandingPageFooter />
                        </Box>

            }
        </Box >
    );
}