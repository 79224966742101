import { Box } from '@mui/material';
import { GOOGLE_MAPS } from '../../../constants/constants';

export default function GoogleMapsEmbed(props) {

    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS}&q=${encodeURIComponent(props.onlyLocation)}&zoom=${17}`;

    return (
        <Box>
            <iframe
                width={props.width ?? '100%'}
                height={props.height ?? '400px'}
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={mapUrl}
            ></iframe>
        </Box>
    )
}