import { useState, useEffect } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import Button from '@mui/material/Button';
import {
    useHistory
} from "react-router-dom";
import { CREATE_ACCOUNT_ROUTE, FAVORITES_ROUTE } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { SIGN_UP_LABEL, VIEW_LABEL } from '../../../i18n/i18nLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useStore } from '../../../hooks/store/store';
import { GET_PROFILE_ROLE } from '../../../constants/store/getters';
import { BORROWER_ROLE } from '../../../constants/constants';

export default function SnackBarSaveProperty({ alert, onClose }) {

    const [openAlert, setOpenAlert] = useState(!!alert?.message);

    const history = useHistory();

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const { state } = useStore();

    useEffect(() => {
        setOpenAlert(!!alert?.message);
    }, [alert]);

    const handleFavorites = () => {
        history.push(FAVORITES_ROUTE);
    }

    const handleSnackbar = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const action = (
        <Button color="secondary" size="small" onClick={() => { handleFavorites(); }}>
            {t(VIEW_LABEL)}
        </Button>
    );

    const signUpAction = (
        <Button color="secondary" size="small" onClick={() => { history.push(CREATE_ACCOUNT_ROUTE) }}>
            {t(SIGN_UP_LABEL)}
        </Button>
    )

    return (
        <Snackbar
            onClick={(e) => { handleSnackbar(e); }}
            autoHideDuration={state[GET_PROFILE_ROLE] === BORROWER_ROLE ? 2000 : 4000}
            open={openAlert}
            onClose={() => onClose()}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                width: "auto",
                marginBottom: isSmOrSmaller ? "72px" : "0px",
            }}
        >
            <SnackbarContent message={t(alert?.message)} action={state[GET_PROFILE_ROLE] === BORROWER_ROLE ? action : signUpAction}>
            </SnackbarContent>
        </Snackbar>

    );
}


