import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import {
    BEDROOMS_LABEL,
    FULL_BATH_LABEL,
    HALF_BATHROOMS_LABEL,
    HOME_SIZE_LABEL,
    NO_PHOTOS_LABEL,
    PHOTOS_LABEL,
    PRICE_LABEL,
    VIEW_ALL_IMAGES_LABEL,
    VIEW_IMAGE_LABEL
} from "../../../i18n/i18nLabel";
import { useTranslation } from 'react-i18next';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { setCurrencyValue } from '../../../utils/functions';
import { useState, lazy } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ViewImage = lazy(() => import('../../Dialogs/ViewImage'));

export default function DetailsPhotos({ images, property }) {

    const { t } = useTranslation();

    const [viewerImages, setViewerImages] = useState(images);

    const [viewImage, setViewImage] = useState(false);

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>{
            isMdOrSmaller
                ? images.length >= 1
                    ? <Box sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                            onClick={() => {
                                setViewerImages([images[0]]);
                                setViewImage(true);
                            }}
                        >
                            <img
                                alt='Property Image'
                                className="imageView"
                                style={{
                                    borderTopLeftRadius: "20px",
                                    borderBottomLeftRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    padding: "2px",
                                    objectFit: 'cover'
                                }}
                                src={images[0]?.mediaUrl}
                                height="400px"
                                width="100%"
                            />
                        </Tooltip>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            marginRight: "8px"
                        }}
                            className="cursor"
                            onClick={() => {
                                setViewerImages(images);
                                setViewImage(true);
                            }}
                        >
                            <GridViewIcon fontSize="small" />
                            <span style={{ fontSize: ".7rem", fontWeight: "bold" }}>
                                {t(VIEW_ALL_IMAGES_LABEL)} {images.length} {t(PHOTOS_LABEL)}
                            </span>
                        </Box>
                    </Box>
                    : <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "20px",
                        paddingBottom: "20px"
                    }}>
                        <HomeOutlinedIcon
                            fontSize="large"
                            style={{ color: "gray" }}
                        />
                        <p style={{
                            color: "grey",
                            margin: "0px"
                        }}>{t(NO_PHOTOS_LABEL)}</p>
                    </Box>
                : images.length >= 5 ?
                    <Box>
                        <Box sx={{ display: "flex", height: "400px" }}>
                            <Box sx={{ width: "45%" }}>
                                <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                    onClick={() => {
                                        setViewerImages([images[0]]);
                                        setViewImage(true);
                                    }}
                                >
                                    <img
                                        alt='Property Image'
                                        className="imageView"
                                        style={{
                                            borderTopLeftRadius: "20px",
                                            borderBottomLeftRadius: "20px",
                                            padding: "2px",
                                            objectFit: 'cover'
                                        }}
                                        src={images[0]?.mediaUrl}
                                        height="400px"
                                        width="100%"
                                    />
                                </Tooltip>
                            </Box>
                            <Box sx={{ width: "27.5%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                        onClick={() => {
                                            setViewerImages([images[1]]);
                                            setViewImage(true);
                                        }}
                                    >
                                        <img
                                            alt='Property Image'
                                            className="imageView"
                                            style={{ padding: "2px", objectFit: 'cover' }}
                                            src={images[1]?.mediaUrl}
                                            height="200px"
                                            width="100%"
                                        />
                                    </Tooltip>
                                    <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                        onClick={() => {
                                            setViewerImages([images[2]]);
                                            setViewImage(true);
                                        }}
                                    >
                                        <img
                                            alt='Property Image'
                                            className="imageView"
                                            style={{ padding: "2px", objectFit: 'cover' }}
                                            src={images[2]?.mediaUrl}
                                            height="200px"
                                            width="100%"
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{ width: "27.5%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                        onClick={() => {
                                            setViewerImages([images[3]]);
                                            setViewImage(true);
                                        }}
                                    >
                                        <img
                                            alt='Property Image'
                                            className="imageView"
                                            style={{ objectFit: 'cover', padding: "2px", borderTopRightRadius: "20px" }}
                                            src={images[3]?.mediaUrl}
                                            height="200px"
                                            width="100%"
                                        />
                                    </Tooltip>
                                    <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                        onClick={() => {
                                            setViewerImages([images[4]]);
                                            setViewImage(true);
                                        }}
                                    >
                                        <img
                                            alt='Property Image'
                                            className="imageView"
                                            style={{ objectFit: 'cover', padding: "2px", borderBottomRightRadius: "20px" }}
                                            src={images[4]?.mediaUrl}
                                            height="200px"
                                            width="100%"
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            marginRight: "8px"
                        }}
                            className="cursor"
                            onClick={() => {
                                setViewerImages(images);
                                setViewImage(true);
                            }}
                        >
                            <GridViewIcon fontSize="small" />
                            <span style={{ fontSize: ".7rem", fontWeight: "bold" }}>
                                {t(VIEW_ALL_IMAGES_LABEL)} {images.length} {t(PHOTOS_LABEL)}
                            </span>
                        </Box>
                    </Box>
                    : images.length >= 1
                        ? <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Tooltip title={t(VIEW_IMAGE_LABEL)} followCursor
                                onClick={() => {
                                    setViewerImages([images[0]]);
                                    setViewImage(true);
                                }}
                            >
                                <img
                                    alt='Property Image'
                                    className="imageView"
                                    style={{
                                        borderTopLeftRadius: "20px",
                                        borderBottomLeftRadius: "20px",
                                        borderBottomRightRadius: "20px",
                                        borderTopRightRadius: "20px",
                                        padding: "2px",
                                        objectFit: 'cover'
                                    }}
                                    src={images[0]?.mediaUrl}
                                    height="400px"
                                    width="100%"
                                />
                            </Tooltip>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                marginRight: "8px"
                            }}
                                className="cursor"
                                onClick={() => {
                                    setViewerImages(images);
                                    setViewImage(true);
                                }}
                            >
                                <GridViewIcon fontSize="small" />
                                <span style={{ fontSize: ".7rem", fontWeight: "bold" }}>
                                    {t(VIEW_ALL_IMAGES_LABEL)} {images.length} {t(PHOTOS_LABEL)}
                                </span>
                            </Box>
                        </Box>
                        : <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px"
                        }}>
                            <HomeOutlinedIcon
                                fontSize="large"
                                style={{ color: "gray" }}
                            />
                            <p style={{
                                color: "grey",
                                margin: "0px"
                            }}>{t(NO_PHOTOS_LABEL)}</p>
                        </Box>
        }
            <ViewImage
                open={viewImage}
                onClose={() => setViewImage(false)}
                images={viewerImages}
                info={
                    t(PRICE_LABEL) + ": $" + setCurrencyValue(property?.price) + ", " +
                    t(BEDROOMS_LABEL) + ": " + property?.bedrooms + ", " +
                    t(FULL_BATH_LABEL) + ": " + property?.bathroomsFull + ", " +
                    t(HALF_BATHROOMS_LABEL) + ": " + property?.bathroomsHalf + ", " +
                    t(HOME_SIZE_LABEL) + ": " + property?.livingArea + " sqft"
                }
            />
        </Box>
    );
}